<template>
    <div name="content" class="my-4 flex flex-1 flex-col items-center justify-center sm:my-8 md:my-12">
        <slot />
    </div>
</template>
<style scoped>
    [name='content']:has(.section-page-banner-text) {
        margin-top: 0;
    }
</style>
